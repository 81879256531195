import { useContext } from "react";

import FeatureTogglesContext from "../context/FeatureTogglesContext";
import { FeatureToggle } from "../consts/feature-toggles";

const useFeatureToggle = (toggleId: FeatureToggle) => {
  const { isEnabled } = useContext(FeatureTogglesContext);

  return isEnabled(toggleId);
};

export default useFeatureToggle;

const getDefaultToggle = (id: FeatureToggle) => {
  if (window.env && window.env.toggles) {
    const { toggles } = window.env;
    return toggles[id]?.enabled || false;
  }

  return false;
};

export const FEATURE_TOGGLE = {
  GENIUS: "genius",
  RATES_BREAKDOWN: "rates-breakdown",
  GENIUS_REPORTS: "genius-report"
} as const;

export type FeatureToggle = (typeof FEATURE_TOGGLE)[keyof typeof FEATURE_TOGGLE];

export const FT_POLL_INTERVAL = 60000;

export const FT_DEFAULTS = Object.fromEntries(
  Object.values(FEATURE_TOGGLE).map(toggle => [toggle, { enabled: getDefaultToggle(toggle) }])
);
